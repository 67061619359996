<template>
    <div class="app-container" style="margin-bottom: 20px">
         <div style="margin-bottom: 20px">
        <el-row>
          <el-col :span="24" align="left">
            <el-button type="primary" @click="goBack">返回</el-button>
          </el-col>
        </el-row>
      </div>
      <el-form
          ref="perForm"
          :model="formData"
          :rules="rules"
          label-width="120px"
        >
           <el-form-item label="活动海报" prop="logoId">
           
            <el-upload
              class="avatar-uploader"
              action="http://up-z0.qiniup.com"
              :show-file-list="false"
              accept="image/jpeg,image/gif,image/png"
              :on-success="handleAvatarSuccess"
              :data="postData"
              :before-upload="beforeAvatarUpload"
            >
              <div v-if="imageUrl" class="avatar">
                <img :src="imageUrl" width="100%"/>
              </div>
  
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          
          </el-form-item>
          <el-form-item label="活动名称" prop="title" >
            <el-input v-model="formData.title"  placeholder="请输入活动名称" style="width:30%" />
          </el-form-item>
          <el-form-item label="活动状态" prop="canSign">
          <el-switch
  v-model="formData.canSign"
  active-text="可报名"
  :active-value=true
  inactive-text="不可报名"
  :inactive-value=false
  @change="upchange"
  >
 
</el-switch>
        </el-form-item>
          <el-form-item label="报名截止时间" prop="endSignTimeMsg"  v-if="formData.canSign==true" >
            <el-date-picker
      v-model="formData.endSignTimeMsg"
      type="datetime"
      placeholder="报名截止时间"
      style="width:30%"
      format
      value-format="yyyy-MM-dd HH:mm"
      @change="change123"
    >
    </el-date-picker>
        </el-form-item>
        <el-form-item label="	报名限制人数" prop="limitPersonNum"  v-if="formData.canSign==true">
            <el-input    type="Number"   @input="change"  v-model="formData.limitPersonNum"  placeholder="请输入报名限制人数" style="width:30%" />
          </el-form-item>

       <el-form-item label="来源类别" prop="originType" v-if="!formData.activityId">
          <el-select
            v-model="formData.originType"
            placeholder="请选择"
            @change="selectChange"
          >
            <el-option
              v-for="(item, index) in options"
              :key="index"
              :label="item.lebal"
              :value="item.status"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="外部链接" prop="url" v-if="formData.originType==1 " >
          <el-input v-model="formData.url" style="width: 100%" />
        </el-form-item>
          <el-form-item label="活动内容" prop="content" v-if="formData.originType==2 " >
            <div style="line-height: normal">
            <uEditor v-model="formData.content"></uEditor>
          </div>
          </el-form-item>
        
        </el-form>
        <el-row slot="footer" type="flex" justify="center">
          <el-col :span="4">
            <el-button size="small" type="primary" @click="btnOK">
              确定
            </el-button>
            <el-button size="small" @click="btnCancel">取消</el-button>
          </el-col>
        </el-row>
    </div>
  </template>
  
  <script>
  import {
    addActivity, 
    updateActivity, 
    getUploadToken
   
  } from "../../request/http";
  export default {
    data() {
      return {
        imageUrl: "",
          postData: {
          token: "",
          key: "", //上传的Logo
        },
        editOradd: "",
        options:[
              
                {
                    lebal:'外部链接',
                    status:1
                },
                {
                    lebal:'本地内容',
                    status:2
                },
               
            ],
        formData:{
          originType:null,
            title: "", 
            logoId:'',
            content:'',
            url:"",
            endSignTimeMsg:"",
            canSign:null,
            limitPersonNum:null,
        },
   
     rules: {
        title: [{ required: true, message: '活动名称不能为空', trigger: 'blur' }],
        logoId: [{ required: true, message: '活动海报不能为空', trigger: 'blur' }],
        content: [{ required: true, message: '活动内容不能为空', trigger: 'blur' }],
        originType: [{ required: true, message: '来源类别不能为空', trigger: 'blur' }],
        url: [{ required: true, message: '外部链接不能为空', trigger: 'blur' }],
        endSignTimeMsg: [{ required: true, message: '报名截止时间不能为空', trigger: 'blur' }],
        limitPersonNum: [{ required: true, message: '报名限制人数不能为空', trigger: 'blur' }],
        
        canSign:[
          { required: true, message: "活动状态不能为空", trigger: "blur" },
        ],
      
        },
        
  
       
      };
    },
    created() {
          
  
      console.log(this.$route.query);
      //editOradd 传过来的为 0 或者 1
      this.editOradd = this.$route.query.editOradd == 0 ? false : true;
      if (this.$route.query.obj) {
        let val = this.$route.query.obj;
        this.imageUrl = "http://file.innopinenut.com/" +val.logoId
        console.log(val);
        if(val.canSign==true){
          this.formData= {
          title:val.title,
        originType:val.originType,
        logoId :val.logoId,
        content :val.content,
        activityId :val.activityId,
        url:val.url,
        canSign:val.canSign,
       endSignTimeMsg:uptime(val.endSignTime),   
        limitPersonNum:val.limitPersonNum
        }
        }
        else {
          this.formData= {
          title:val.title,
        originType:val.originType,
        logoId :val.logoId,
        content :val.content,
        activityId :val.activityId,
        url:val.url,
        canSign:val.canSign,
      
        }
        }
      
      }
    },
    mounted() {},
  
    methods: {
      upchange(value){
      this.formData.canSign =value
      console.log(this.formData.canSign);
    },

      selectChange(value) {
      this.formData.originType = value;
      console.log(this.formData.originType);
    },
    change(val){
      console.log(val);
    },

      
      change123(val){
    this.formData.endSignTimeMsg = val
          console.log(this.formData.endSignTimeMsg);
  },
     // 上传图片方法
     handleAvatarSuccess(res, file) {
        console.log("上传成功", res, file);
        this.imageUrl = "http://file.innopinenut.com/" + res.key;
        this.formData.logoId= res.key;
       },
       async beforeAvatarUpload(file) {
        // console.log("上传前");
        let { data } = await getUploadToken();
        // console.log(data);
        if (data == "" || data == null) {
          this.message.error("上传图片时获取Token失败！");
          return;
        }
        this.postData.token = data.token;
        this.postData.key = data.fid;
      },
     
      // 返回上一级页面
      goBack() {
        this.$router.go(-1);
      },
      // 确认
        async btnOK() {
        this.$refs.perForm.validate().then(async () => {
          if (this.formData.activityId) {
   
            let { code, msg } = await updateActivity(this.formData);
            if (this.formData.activityId && code == 0) {
              this.$message.success("编辑成功");
            } else {
              console.log(msg);
              this.$message({
                type: "error",
                message: msg,
              });
            }
            this.goBack();
          } else {
          
            let { code, msg } = await addActivity(this.formData);
            console.log(code, msg);
            if (code == 0) {
              this.$message.success("新增成功");
            } else {
              console.log(msg);
              this.$message({
                type: "error",
                message: msg,
              });
            }
            this.goBack();
          }
        });
     
      },
  
      //弹窗取消函数
      btnCancel() {
        this.goBack();
      },
    
  
     
    
    },
  };
  function uptime(nS) {
  let date = new Date(nS);
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  let h = date.getHours();
  let m = date.getMinutes();
  h = h < 10 ? ('0' + h) : h;
  m = m < 10 ? ('0' + m) : m;

  month = month < 10 ? "0" + month : month;
  day = day < 10 ? "0" + day : day;
  date = year + '-' + month + '-' + day + ' ' + h + ':' + m 
  // 2018-10-09
  return date;
}

  </script>
  
  <style>
  
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  </style>